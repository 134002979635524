import React from "react";
import CanvasStars from "../components/CanvasStars";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <CanvasStars />
      <Outlet />
    </div>
  );
};

export default MainLayout;
