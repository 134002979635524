import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Select from 'react-select';
import MediaComponent from "../components/MediaComponent";

const Container = styled.div`
  font-family: Arial, sans-serif;
  padding: 20px;
`;

const SelectWrapper = styled.div`
  margin-bottom: 20px;
`;

const PollContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
`;

const Question = styled.h2`
  color: #333;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;

const AnswerButton = styled.div`
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: ${props => props.selected ? "#ffcccb" : "#f5f5f5"};
  border: 2px solid ${props => props.selected ? "#ff0000" : "#ddd"};
  border-radius: 8px;
  font-size: 16px;
  text-align: left;
  box-sizing: border-box;
`;

const ResultMessage = styled.p`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  color: ${props => props.isCorrect ? "green" : "red"};
  width: 100%;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 20px;
  font-weight: bold;
`;

const ReactionContainer = styled.div`
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 15px;
`;

const LoadingMessage = styled.p`
  font-style: italic;
  color: #666;
`;

const FeedDetailsContainer = styled.div`
  background-color: #f8f8f8;
  border-top: 1px solid #eaeaea;
  padding: 10px;
  margin-top: 15px;
  font-size: 14px;
`;

const FeedDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const FeedDetailsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FeedDetailsTimes = styled.div`
  display: flex;
`;

const FeedDetailsTime = styled.span`
  margin-right: 15px;
`;

const AssetInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const AssetNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AssetName = styled.h3`
  font-size: 1.4em;
  margin: 0;
  padding: 0;
`;

const FeedName = styled.p`
  font-size: 0.9em;
  margin: 5px 0 0 0;
  color: #666;
`;

const ReactionTime = styled.p`
  font-size: 0.8em;
  color: #666;
  margin: 0;
  text-align: right;
`;

const BrandContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const BrandIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
`;

const BrandImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BrandName = styled.p`
  font-size: 1em;
  margin: 0;
`;

const Caption = styled.p`
  font-size: 1em;
  margin: 10px 0;
  color: #333;
`;

// Components
const TextComponent = ({ assetContent }) => (
  <div>
    <h3>Text Content</h3>
    <p>{assetContent.text}</p>
  </div>
);


const PollResult = ({ assetContent, userChoices }) => {
  return (
    <PollContainer>
      <Question>{assetContent.question}</Question>
      {Object.entries(assetContent.selections).map(([key, value]) => (
        <AnswerButton
          key={key}
          selected={userChoices.includes(value)}
        >
          {value}
        </AnswerButton>
      ))}
      {assetContent.pollType === "quiz" && (
        <ResultMessage isCorrect={userChoices.includes(assetContent.answer)}>
          {userChoices.includes(assetContent.answer) 
            ? "Correct!" 
            : `Incorrect. The correct answer was "${assetContent.answer}"`}
        </ResultMessage>
      )}
    </PollContainer>
  );
};

const FeedDetails = ({ sessionLink, sessionStartTime, sessionEndTime }) => {
  return (
    <FeedDetailsContainer>
      <FeedDetailsHeader>
        <span role="img" aria-label="info" style={{ marginRight: '5px' }}>ℹ️</span>
        <strong>Feed Details</strong>
      </FeedDetailsHeader>
      <FeedDetailsContent>
        <FeedDetailsTimes>
          <FeedDetailsTime>
            <span role="img" aria-label="clock" style={{ marginRight: '5px' }}>🕒</span>
            Start: {sessionStartTime}
          </FeedDetailsTime>
          <FeedDetailsTime>
            <span role="img" aria-label="clock" style={{ marginRight: '5px' }}>🕒</span>
            End: {sessionEndTime}
          </FeedDetailsTime>
        </FeedDetailsTimes>
        <div>
          <span role="img" aria-label="link" style={{ marginRight: '5px' }}>🔗</span>
          <a href={sessionLink} target="_blank" rel="noopener noreferrer">Session Link</a>
        </div>
      </FeedDetailsContent>
    </FeedDetailsContainer>
  );
};

const UserReactions = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [hashedReactions, setHashedReactions] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://GenZ.feeltiptop.com/api/get_user');
      const formattedUsers = response.data.map(user => ({
        value: user.UserID,
        label: `${user.firstName} ${user.lastName} (${user.UserName})`
      }));
      setUsers(formattedUsers);
    } catch (err) {
      setError('Failed to fetch users. Please try again.');
    }
  };

  const fetchReactions = async (userId) => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`https://GenZ.feeltiptop.com/api/user_reaction`, {
        params: { userID: userId }
      });
      const data = response.data;
      if (data.success) {
        createHashedReactions(data.reactions);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch reactions. Please try again.');
    }
    setLoading(false);
  };

  const createHashedReactions = (reactions) => {
    const newReactions = reactions.reduce((acc, current) => {
      const combinedKey = `${current.reaction.assetID}_${current.reaction.reactionTime}`;
      if(acc[combinedKey]) {
        acc[combinedKey].push(current);
      } else {
        acc[combinedKey] = [current];
      }
      return acc;
    }, {});
    setHashedReactions(newReactions);
  }

  const handleUserSelect = (selectedOption) => {
    setSelectedUser(selectedOption);
    setHashedReactions({});
    setError('');
    if (selectedOption) {
      fetchReactions(selectedOption.value);
    }
  };

  const renderAssetContent = (reactions) => {
    const assetContent = reactions[0].asset.assetContent;
    if (assetContent.assetType === "poll") {
      const userChoices = reactions.flatMap(reaction => 
        reaction.reaction.reactionDetails.pollChoice 
          ? [reaction.reaction.reactionDetails.pollChoice] 
          : []
      );
      return <PollResult assetContent={assetContent} userChoices={userChoices} />;
    } else if (assetContent.assetType === "text") {
      return <TextComponent assetContent={assetContent} />;
    } else if (assetContent.assetType === "media") {
      console.log(assetContent.mediaContent);
      return <MediaComponent mediaLink={assetContent.mediaContent} />;
    } else {
      return <p>Unsupported asset type: {assetContent.assetType}</p>;
    }
  };

  const renderReactionDetails = (reactions) => {
    return reactions.map(reaction => {
      const details = [];
      if (reaction.reaction.reactionDetails.ranking) {
        details.push(<p key="ranking">Ranking: {reaction.reaction.reactionDetails.ranking}</p>);
      }
      if (reaction.reaction.reactionDetails.comment) {
        details.push(<p key="comment">Comment: {reaction.reaction.reactionDetails.comment}</p>);
      }
      return details.length > 0 ? (
        <div key={reaction.reaction.reactionID}>
          <h4>Reaction Details:</h4>
          {details}
        </div>
      ) : null;
    });
  };

  return (
    <Container>
      <SelectWrapper>
        <Select
          options={users}
          value={selectedUser}
          onChange={handleUserSelect}
          placeholder="Search and select a user"
          isClearable
        />
      </SelectWrapper>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {loading && <LoadingMessage>Loading reactions...</LoadingMessage>}

      {Object.keys(hashedReactions).length > 0 && (
        <div>
          <h2>User Reactions for {selectedUser.label}</h2>
          {Object.entries(hashedReactions).map(([key, reactions]) => (
            <ReactionContainer key={key}>
              <AssetInfoContainer>
                <AssetNameContainer>
                  <AssetName>{reactions[0].asset.assetName}</AssetName>
                  <FeedName>{reactions[0].feed.name}</FeedName>
                </AssetNameContainer>
                <ReactionTime>
                  🕒 {new Date(reactions[0].reaction.reactionTime).toLocaleString()}
                </ReactionTime>
              </AssetInfoContainer>
              
              <BrandContainer>
                <BrandIcon>
                  {reactions[0].brand.brandProfilePicture ? (
                    <BrandImage 
                      src={`https://GenZ.feeltiptop.com/${reactions[0].brand.brandProfilePicture}`} 
                      alt={reactions[0].brand.brandName}
                    />
                  ) : (
                    <BrandImage 
                      src="https://upload.wikimedia.org/wikipedia/commons/a/a7/Blank_image.jpg" 
                      alt="Blank Company Icon"
                    />
                  )}
                </BrandIcon>
                <BrandName>{reactions[0].brand.brandName}</BrandName>
              </BrandContainer>

              <Caption>{reactions[0].asset.caption}</Caption>
              
              {renderAssetContent(reactions)}
              {renderReactionDetails(reactions)}
              
              <FeedDetails 
                sessionLink={reactions[0].feed.sessionLink}
                sessionStartTime={reactions[0].feed.sessionStartTime}
                sessionEndTime={reactions[0].feed.sessionEndTime}
              />
            </ReactionContainer>
          ))}
        </div>
      )}
    </Container>
  );
};

export default UserReactions;