import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/GenZ_Logo.svg";
import "../App.css";

const LandingPage = () => {
  return (
    <div className="landing-page">
      <img src={logo} alt="GenZ Logo" className="logo" loading="lazy" />
      <p className="centered-text">
        This app is for GenZ Users already accepted into the 360 network.
      </p>
      <Link
        to="/login"
        style={{
          padding: "20px 40px",
          display: "flex",
          borderRadius: "10px",
          backgroundColor: "#ff2a2f",
          width: "200px",
          height: "50px",
          color: "white",
          opacity: "90%",
          fontSize: "24px",
          justifyContent: "center",
          alignItems: "center",
          textDecoration: "none",
          marginBottom: "20px",
          fontFamily: "'Acumin-RPro', sans-serif",
        }}
      >
        Let's Go!
      </Link>
      <p className="smallCentered-text">Not a GenZ User yet?</p>
      <Link to="/signup" className="apply">
        Sign up <span className="highlight">here</span>
      </Link>
    </div>
  );
};

export default LandingPage;
