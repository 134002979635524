import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import axios from "axios";

const mediaCache = {};

const MediaComponent = React.memo(function MediaComponent({ mediaLink }) {
  const [mediaData, setMediaData] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const mediaStyle = {
    width: "100%",
    minHeight: "auto",
  };

  const isUploadedFile = (link) => {
    return link && link.startsWith("media/assets");
  };

  const isWebScreenCapture = (link) => {
    return link && link.includes("webscreencapture.tiptopbest.com");
  };

  const getWebScreenCaptureUrl = (link) => {
    const encodedUrl = encodeURIComponent(link);
    return `https://GenZ.feeltiptop.com/api/get_media?url=${encodedUrl}`;
  };

  useEffect(() => {
    const fetchMedia = async () => {
      if (
        mediaLink &&
        !isUploadedFile(mediaLink) &&
        isWebScreenCapture(mediaLink)
      ) {
        if (mediaCache[mediaLink]) {
          setMediaData(mediaCache[mediaLink].data);
          setMediaType(mediaCache[mediaLink].type);
          setLoading(false);
          setError(null);
          return;
        }

        try {
          setLoading(true);
          setError(null);
          const response = await axios.get(getWebScreenCaptureUrl(mediaLink), {
            responseType: "arraybuffer",
            timeout: 30000,
          });
          const contentType = response.headers["content-type"];
          const base64 = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              "",
            ),
          );
          const mediaData = `data:${contentType};base64,${base64}`;
          const mediaType = contentType.split("/")[0];

          // Store the fetched media in the cache
          mediaCache[mediaLink] = { data: mediaData, type: mediaType };

          setMediaData(mediaData);
          setMediaType(mediaType);
          setLoading(false);
        } catch (err) {
          console.error("Error fetching media:", err);
          setError("Failed to load media");
          setLoading(false);
        }
      } else {
        setLoading(false);
        setError(null);
      }
    };
    fetchMedia();
  }, [mediaLink]);

  const handleMediaError = (e) => {
    console.error("Media loading error:", e);
    setError("Failed to load media");
  };

  if (!mediaLink) {
    return <div>No media link provided</div>;
  }

  if (isUploadedFile(mediaLink)) {
    const fullUrl = `https://GenZ.feeltiptop.com/${mediaLink}`;
    if (ReactPlayer.canPlay(fullUrl)) {
      return (
        <ReactPlayer
          url={fullUrl}
          controls
          playIcon={true}
          width="100%"
          height={
            ReactPlayer.canPlay(fullUrl) && fullUrl.includes(".mp3")
              ? "50px"
              : "max(100px, 40vh)"
          }
          playsinline
          playing
          muted
          onError={(e) => console.error("ReactPlayer error:", e)}
        />
      );
    } else {
      return (
        <img
          src={fullUrl}
          alt="Uploaded Content"
          style={mediaStyle}
          onError={handleMediaError}
        />
      );
    }
  } else if (isWebScreenCapture(mediaLink)) {
    return (
      <>
        {loading && <div>Loading...</div>}
        {error && <div>{error}</div>}
        {mediaData && mediaType === "video" && (
          <ReactPlayer
            url={mediaData}
            controls
            width="100%"
            height="max(100px, 40vh)"
            playsinline
            playing={true}
            onError={handleMediaError}
          />
        )}
        {mediaData && mediaType === "image" && (
          <img
            src={mediaData}
            alt="Web Screen Capture"
            style={mediaStyle}
            onError={handleMediaError}
          />
        )}
      </>
    );
  } else {
    console.log("Normal link to a file");
    if (ReactPlayer.canPlay(mediaLink)) {
      return (
        <ReactPlayer
          url={mediaLink}
          controls
          width="100%"
          height={
            ReactPlayer.canPlay(mediaLink) && mediaLink.includes(".mp3")
              ? "50px"
              : "max(100px, 40vh)"
          }
          playsinline
          playing
          muted
          onError={(e) => console.error("ReactPlayer error:", e)}
        />
      );
    } else {
      return (
        <img
          src={mediaLink}
          alt="Linked Content"
          style={mediaStyle}
          onError={handleMediaError}
        />
      );
    }
  }
});

export default MediaComponent;
