import React, { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import backArrow from "../assets/images/arrow_back.svg";
import logo from "../assets/images/GenZ_Logo.svg";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import CryptoJS from "crypto-js";
import axios from "axios";
import GenZLandingPageWallpaper from "../assets/images/GenZLandingPageWallpaper.svg";

const SignUp = (props) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [accept, setAccept] = useState(false);
  const [consent, setConsent] = useState(false);
  const [error, setError] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [emailAvailable, setEmailAvailable] = useState(true);
  const [phoneNumberAvailable, setPhoneNumberAvailable] = useState(true);
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const navigate = useNavigate();

  document.title = "GenZ Sign Up";

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const checkAvailability = useCallback((field, value, setter) => {
    if (value.length > 0) {
      setCheckingAvailability(true);
      axios
        .post("https://GenZ.feeltiptop.com/api/checkAvailability", { field, value })
        .then((response) => {
          setter(response.data.available);
          setCheckingAvailability(false);
        })
        .catch((error) => {
          console.error("Error checking availability:", error);
          setCheckingAvailability(false);
        });
    } else {
      setter(true);
    }
  }, []);

  const checkUsernameAvailability = useCallback(
    debounce(
      (value) => checkAvailability("username", value, setUsernameAvailable),
      1500,
    ),
    [debounce, checkAvailability],
  );
  const checkEmailAvailability = useCallback(
    debounce(
      (value) => checkAvailability("email", value, setEmailAvailable),
      1500,
    ),
    [debounce, checkAvailability],
  );
  const checkPhoneNumberAvailability = useCallback(
    debounce(
      (value) =>
        checkAvailability("phoneNumber", value, setPhoneNumberAvailable),
      1500,
    ),
    [debounce, checkAvailability],
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const checkPassword = (pw) => {
    const decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    return pw !== "" && decimal.test(pw);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 10);
    setPhoneNumber(value);
    checkPhoneNumberAvailability(value);
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    if (value.length >= 3) {
      checkUsernameAvailability(value);
    }
  };

  const handleSignUp = () => {
    if (!checkPassword(password)) {
      setError(
        "Password does not meet the criteria. Please ensure it is at least 8 characters long and contains at least one lowercase letter, one uppercase letter, one number, and one special character.",
      );
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (!accept || !consent) {
      setError(
        "Please accept the terms and conditions and consent to receive text messages.",
      );
      return;
    }

    if (!usernameAvailable || !emailAvailable || !phoneNumberAvailable) {
      setError("Please ensure all fields are available.");
      return;
    }

    if (!buttonClicked) {
      setButtonClicked(true);
      setError("");

      const hashedPassword = CryptoJS.SHA256(password).toString();

      axios
        .post("https://GenZ.feeltiptop.com/api/token", { passCode: "4zPoQ5KAkR" })
        .then((response) => {
          props.setToken(response.data.access_token);

          const payload = {
            phoneNumber: phoneNumber || null,
            userName: username,
            password: hashedPassword,
            firstName,
            lastName,
            email,
            termsAndConditions: accept ? "Accepted" : "Not Accepted",
            textMessaging: consent ? "Consented" : "Not Consented",
          };

          return axios.post("https://GenZ.feeltiptop.com/api/addUser", payload, {
            headers: {
              Authorization: "Bearer " + response.data.access_token,
            },
          });
        })
        .then((response) => {
          console.log("Success:", response.data);
          navigate("/thankyou", { state: { text: "for signing up!" } });
        })
        .catch((error) => {
          setButtonClicked(false);
          setError("Unable to sign up - Error " + error);
          console.error("Error:", error);
        });
    }
  };

  const isFormValid =
    username &&
    email &&
    firstName &&
    lastName &&
    password &&
    confirmPassword &&
    accept &&
    consent &&
    usernameAvailable &&
    emailAvailable &&
    phoneNumberAvailable &&
    !checkingAvailability;

  return (
    <div
      className="flex min-h-screen items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${GenZLandingPageWallpaper})` }}
    >
      <div className="z-10 w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
        <div className="mb-3">
          <Link to="/">
            <img
              src={backArrow}
              alt="Back"
              className="h-6 w-6 hover:scale-105"
            />
          </Link>
        </div>
        <h2 className="mb-4 text-center text-2xl font-bold">
          Create Your Account
        </h2>
        <div className="mb-3 w-full">
          <input
            type="text"
            placeholder="Username"
            className={`w-full rounded-md bg-gray-100 p-2 text-sm ${usernameAvailable ? "" : "border border-red-500"} ${focusedInput === "username" ? "border border-black" : ""}`}
            value={username}
            onChange={handleUsernameChange}
            onFocus={() => setFocusedInput("username")}
            onBlur={() => setFocusedInput(null)}
          />
          {!usernameAvailable && (
            <p className="mt-2 text-xs text-red-500">Username already exists</p>
          )}
        </div>
        <div className="mb-3 w-full">
          <input
            type="email"
            placeholder="Email"
            className={`w-full rounded-md bg-gray-100 p-2 text-sm ${emailAvailable ? "" : "border border-red-500"} ${focusedInput === "email" ? "border border-black" : ""}`}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              checkEmailAvailability(e.target.value);
            }}
            onFocus={() => setFocusedInput("email")}
            onBlur={() => setFocusedInput(null)}
          />
          {!emailAvailable && (
            <p className="mt-2 text-xs text-red-500">Email already exists</p>
          )}
        </div>
        <div className="mb-3 w-full">
          <input
            type="text"
            placeholder="First Name"
            className="w-full rounded-md bg-gray-100 p-2 text-sm"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onFocus={() => setFocusedInput("firstName")}
            onBlur={() => setFocusedInput(null)}
          />
        </div>
        <div className="mb-3 w-full">
          <input
            type="text"
            placeholder="Last Name"
            className="w-full rounded-md bg-gray-100 p-2 text-sm"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onFocus={() => setFocusedInput("lastName")}
            onBlur={() => setFocusedInput(null)}
          />
        </div>
        <div className="relative mb-4 w-full">
          <input
            type="tel"
            placeholder="Phone Number (Optional)"
            className={`w-full rounded-md bg-gray-100 p-2 text-sm ${phoneNumberAvailable ? "" : "border border-red-500"} ${focusedInput === "phoneNumber" ? "border border-black" : ""}`}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onFocus={() => setFocusedInput("phoneNumber")}
            onBlur={() => setFocusedInput(null)}
            maxLength={10}
          />
          {!phoneNumberAvailable && (
            <p className="text-xs text-red-500">Phone number already exists</p>
          )}
        </div>
        <div className="relative mb-4 w-full">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            className={`w-full rounded-md bg-gray-100 p-2 text-sm ${focusedInput === "password" ? "border border-black" : ""}`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={() => setFocusedInput("password")}
            onBlur={() => setFocusedInput(null)}
          />
          <button
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-3 top-1/2 -translate-y-1/2 transform"
          >
            {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
          </button>
        </div>
        <div className="relative mb-4 w-full">
          <input
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
            className={`w-full rounded-md bg-gray-100 p-2 text-sm ${focusedInput === "confirmPassword" ? "border border-black" : ""}`}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onFocus={() => setFocusedInput("confirmPassword")}
            onBlur={() => setFocusedInput(null)}
          />
          <button
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute right-3 top-1/2 -translate-y-1/2 transform"
          >
            {showConfirmPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
          </button>
        </div>
        <div className="w-full">
          <label className="flex gap-2 text-xs">
            <input
              type="checkbox"
              className=""
              checked={accept}
              onChange={() => setAccept(!accept)}
            />
            I accept the Terms and Conditions
          </label>
          <label className="mb-4 mt-2 flex gap-2 text-xs">
            <input
              type="checkbox"
              className=""
              checked={consent}
              onChange={() => setConsent(!consent)}
            />
            I consent to receiving text messages from GenZ.
          </label>
        </div>
        {error && (
          <div className="m-2 text-xs text-red-500">
            <p>{error}</p>
          </div>
        )}
        <button
          className={`w-full cursor-pointer rounded-md p-2 text-sm font-bold ${isFormValid && !buttonClicked ? "bg-green-500 text-white hover:bg-green-600" : "bg-gray-200 text-gray-500"}`}
          disabled={!isFormValid || buttonClicked}
          onClick={handleSignUp}
        >
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default SignUp;
